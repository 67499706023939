/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Grid,
  Button,
  Typography,
  Box,
  Paper,
  Container,
  Link,
  Tooltip,
  Divider,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import Carousel from "react-simply-carousel";
import Carousel from 'react-material-ui-carousel';
import {
  Link as ScrollLink,
  DirectLink,
  Element,
  Events,
  animateScroll,
  scrollSpy,
  scroller,
} from 'react-scroll';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import useCommunityList from '../../../hooks/useCommunityList';
import Residents from '../Residents/Residents';
import InsightsBy from '../InsightsBy/InsightsBy';
import AboutCommunity from '../AboutCommunity/AboutCommunity';
import CostOfLiving from '../CostOfLiving/CostOfLiving';
import Amenities from '../Amenities/Amenities';
import Jobs from '../Jobs/Jobs';
import CommunityCard from '../CommunityList/CommunityCard/CommunityCard';
import CommunityMap from './CommunityMap';
import HouseList from '../HouseList/HouseList';
import ArrowLeft from '../../../assets/images/Arrow-Left.png';
import ArrowRight from '../../../assets/images/Arrow-Right.png';
import { ReactComponent as PersonIcon } from '../../../assets/images/User.svg';
import { ReactComponent as EditPenIcon } from '../../../assets/images/EditPenWhite.svg';
import { ReactComponent as HouseIcon } from '../../../assets/images/house.svg';

import ViewPublic from '../ViewPublic/ViewPublic';
import CommunityProfileTracker from './CommunityProfileTracker';
import Climate from '../Climate/Climate';
import GettingAround from '../GettingAround/GettingAround';
import Education from '../Education/Education';
import Healthcare from '../Healthcare/HealthCare';
import CommunityHealthInsights from './CommunityHealthInsights/CommunityHealthInsights';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import { backendUrl, homesBaseUrlForCommunity } from '../../../constants/globalConstants';
import CommunityCoverPhoto from '../CommunityCoverPhoto/CommunityCoverPhoto';
import Loader from '../../../components/Loader/Loader';
const CommunityDetails = (props) => {
  const loc = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const key = 'iFrasCwdJh';
  const [communityDetails, setCommunityDetails] = useState(null);
  const [isVideoApproved, setVideoApproved] = useState(true);
  const { getCommunityDetailById, readVideoNotification } = useCommunityList();
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const [userId] = useState(loggedInUserData?.id);
  const [isCommunityRepresentative, setCommunityRepresentative] = useState(
    params?.id === loggedInUserData?.communityId,
  );

  const [isAccessible, setIsAccessible] = React.useState(
    (loggedInUserData?.id && communityDetails?.userrole?.[0]?.id === loggedInUserData?.id) ||
      loggedInUserData?.roleId === 2,
  );
  const [isAdmin, setIsAdmin] = React.useState(loggedInUserData?.roleId === 2);

  const [publicView, setPublicView] = useState(false);

  const fillCommunityDetails = async () => {
    let communityDetailsResults = [];
    if (params?.id) {
      communityDetailsResults = await getCommunityDetailById({
        communityId: params?.id,
        userId,
      });
      setCommunityDetails(communityDetailsResults?.data);
    }
    setCommunityRepresentative(
      params?.id === loggedInUserData?.communityId && communityDetailsResults?.data?.isRepresented,
    );
    // return communityDetailsResults?.data;
  };

  const checkVideoStatus = async () => {
    // Function to close the video approval box
    const response = await readVideoNotification({ videoId: videoData.requestId }); // Use communityId
    if (response.status === true) {
      //console.log(response);
    }
    setVideoApproved(false);
  };

  const hashFragment = loc.hash.substring(1);

  useEffect(() => {
    // Scroll to Section1 with offset of -100 when component mounts
    if (hashFragment) {
      setTimeout(() => {
        scroller.scrollTo(hashFragment, {
          duration: 500,
          spy: true,
          smooth: true,
          offset: -100,
        });
      }, 1500);
    }
  }, [loc, communityDetails]);

  useEffect(() => {
    //checkVideoStatus();
  }, []);
  // const sectionRef = useRef(null);
  // useLayoutEffect(() => {
  //   const sectionElement = document.querySelector('[name="Section2"]');
  //   if (hashFragment) {
  //     console.log(sectionElement, 4444);
  //   }
  // }, []);

  useEffect(() => {
    // Check if the query parameter is present
    if (loc.search.includes('?section=section3')) {
      // Use react-router-dom navigate to scroll to the hash fragment
      navigate(loc.pathname + '#Section3');
    }
  }, [navigate, loc.search, loc.pathname]);

  useEffect(() => {
    fillCommunityDetails();
  }, []);
  const [activeSlide, setActiveSlide] = useState(0);
  var items = [
    {
      Image: 'assets/amenities/Slide.jpg',
    },
    {
      Image: 'assets/amenities/Slide.jpg',
    },
  ];
  useEffect(() => {
    setIsAccessible(
      (loggedInUserData?.id && communityDetails?.userrole?.[0]?.id === loggedInUserData?.id) ||
        loggedInUserData?.roleId === 2,
    );
  }, [communityDetails]);

  const ViewButton = () => (
    <>
      View all homes in {communityDetails.communityNameText}
      <Box component="span" marginLeft={1}>
        <ArrowForwardIcon />
      </Box>
    </>
  );

  return (
    <>
      {console.log(communityDetails, 2222)}
      {communityDetails ? (
        <Box component="div" className="communityDetailsContainer PageSpacing">
          <Box
            component="div"
            className="communityDetailsBannerbg"
            style={{
              backgroundImage: `url(
                      ${require(`../../../assets/images/CD-Pattern.jpg`).default}
                    )`,
            }}
          ></Box>
          <Container className="CustomContainer">
            {/* <Paper className="BackButton">
            <Box className="FlexCenter">
              <ArrowBackIcon color='primary' />
              <ScrollLink
                className="BackButtonLink"
                onClick={() => {
                  navigate('../community-list');
                }}
              >
                {communityDetails?.communityNameText}
              </ScrollLink>
            </Box>
            <MoreHorizIcon className="GreenColor" />
          </Paper> */}
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              className="communityDetailsContent"
            >
              <Grid item xs={12} sm={4} className="communityDetailsSidebar">
                <Box className="SidebarContent">
                  <ul className="nav navbar-nav">
                    <If condition={!publicView}>
                      {!isAccessible &&
                      ((communityDetails?.amenities?.length &&
                        communityDetails?.amenities?.find(
                          (x) => x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                        )) ||
                        (communityDetails?.amenityDescription?.length &&
                          communityDetails?.amenityDescription?.find(
                            (x) => x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                          )?.amenityDesc != '') ||
                        false) ? (
                        <li>
                          <ScrollLink
                            activeClass="active"
                            className="Section1"
                            to="Section1"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={-100}
                          >
                            Amenities
                          </ScrollLink>
                        </li>
                      ) : (
                        isAccessible && (
                          <li>
                            <ScrollLink
                              activeClass="active"
                              className="Section1"
                              to="Section1"
                              spy={true}
                              smooth={true}
                              duration={500}
                              offset={-100}
                            >
                              Amenities
                            </ScrollLink>
                          </li>
                        )
                      )}
                    </If>

                    <If condition={publicView}>
                      {
                        <If
                          condition={
                            isAccessible &&
                            ((communityDetails?.amenities?.length &&
                              communityDetails?.amenities?.find(
                                (x) =>
                                  x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                              )) ||
                              (communityDetails?.amenityDescription?.length &&
                                communityDetails?.amenityDescription?.find(
                                  (x) =>
                                    x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                                )?.amenityDesc != '') ||
                              false)
                          }
                        >
                          <li>
                            <ScrollLink
                              activeClass="active"
                              className="Section1"
                              to="Section1"
                              spy={true}
                              smooth={true}
                              duration={500}
                              offset={-100}
                            >
                              Amenities
                            </ScrollLink>
                          </li>
                        </If>
                      }
                    </If>

                    <If condition={!publicView}>
                      {(!isAccessible &&
                        communityDetails?.communityVideo &&
                        communityDetails?.communityVideo.length > 0 &&
                        communityDetails?.communityVideo
                          ?.find(
                            (x) => x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                          )
                          ?.approvedStatus?.toLowerCase() == 'approved') ||
                      (!isAccessible &&
                        communityDetails?.aboutDesc &&
                        communityDetails?.aboutDesc !== '') ? (
                        <li>
                          <ScrollLink
                            activeClass="active"
                            className="Section3"
                            to="Section3"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={-100}
                          >
                            About
                          </ScrollLink>
                        </li>
                      ) : (
                        isAccessible && (
                          <li>
                            <ScrollLink
                              activeClass="active"
                              className="Section3"
                              to="Section3"
                              spy={true}
                              smooth={true}
                              duration={500}
                              offset={-100}
                            >
                              About
                            </ScrollLink>
                          </li>
                        )
                      )}
                    </If>

                    <If condition={publicView}>
                      <If
                        condition={
                          (communityDetails?.communityVideo &&
                            communityDetails?.communityVideo.length > 0 &&
                            communityDetails?.communityVideo
                              ?.find(
                                (x) =>
                                  x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                              )
                              ?.approvedStatus?.toLowerCase() == 'approved') ||
                          (communityDetails?.aboutDesc && communityDetails?.aboutDesc !== '')
                        }
                      >
                        <li>
                          <ScrollLink
                            activeClass="active"
                            className="Section3"
                            to="Section3"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={-100}
                          >
                            About
                          </ScrollLink>
                        </li>
                      </If>
                    </If>

                    <li>
                      <ScrollLink
                        activeClass="active"
                        className="Section5"
                        to="Section5"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-100}
                      >
                        Jobs
                      </ScrollLink>
                    </li>

                    <li>
                      <ScrollLink
                        activeClass="active"
                        className="Section6"
                        to="Section6"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-100}
                      >
                        Residents
                      </ScrollLink>
                    </li>

                    <li>
                      <ScrollLink
                        activeClass="active"
                        className="Section7"
                        to="Section7"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-100}
                      >
                        Cost of Living
                      </ScrollLink>
                    </li>

                    <If condition={!publicView}>
                      <Choose>
                        <When
                          condition={
                            !isAccessible &&
                            communityDetails?.climate &&
                            communityDetails?.climate?.length > 0 &&
                            communityDetails?.climate?.[0] &&
                            (communityDetails?.climate?.[0]?.climateDesc !== '' ||
                              communityDetails?.climate?.[0]?.climateType !== '')
                          }
                        >
                          <li>
                            <ScrollLink
                              activeClass="active"
                              className="Section8"
                              to="Section8"
                              spy={true}
                              smooth={true}
                              duration={500}
                              offset={-100}
                            >
                              Climate
                            </ScrollLink>
                          </li>
                        </When>
                        <When condition={isAccessible}>
                          <li>
                            <ScrollLink
                              activeClass="active"
                              className="Section8"
                              to="Section8"
                              spy={true}
                              smooth={true}
                              duration={500}
                              offset={-100}
                            >
                              Climate
                            </ScrollLink>
                          </li>
                        </When>
                      </Choose>
                    </If>

                    <If condition={publicView}>
                      <If
                        condition={
                          communityDetails?.climate &&
                          communityDetails?.climate?.length > 0 &&
                          communityDetails?.climate?.[0] &&
                          (communityDetails?.climate?.[0]?.climateDesc !== '' ||
                            communityDetails?.climate?.[0]?.climateType !== '')
                        }
                      >
                        <li>
                          <ScrollLink
                            activeClass="active"
                            className="Section8"
                            to="Section8"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={-100}
                          >
                            Climate
                          </ScrollLink>
                        </li>
                      </If>
                    </If>

                    <If condition={!publicView}>
                      <Choose>
                        <When
                          condition={
                            !isAccessible &&
                            communityDetails?.gettingAround.length > 0 &&
                            communityDetails?.gettingAround?.[0] &&
                            (communityDetails?.gettingAround?.[0]?.dedicatedBikingPaths ||
                              communityDetails?.gettingAround?.[0]?.dedicatedWalkingPaths ||
                              communityDetails?.gettingAround?.[0]?.regionalAirport ||
                              communityDetails?.gettingAround?.[0]?.localAirport ||
                              communityDetails?.gettingAround?.[0]?.publicTransType !== '' ||
                              communityDetails?.gettingAround?.[0]?.gettingAroundDesc !== '')
                          }
                        >
                          <li>
                            <ScrollLink
                              activeClass="active"
                              className="Section9"
                              to="Section9"
                              spy={true}
                              smooth={true}
                              duration={500}
                              offset={-100}
                            >
                              Getting Around
                            </ScrollLink>
                          </li>
                        </When>
                        <When condition={isAccessible}>
                          <li>
                            <ScrollLink
                              activeClass="active"
                              className="Section9"
                              to="Section9"
                              spy={true}
                              smooth={true}
                              duration={500}
                              offset={-100}
                            >
                              Getting Around
                            </ScrollLink>
                          </li>
                        </When>
                      </Choose>
                    </If>

                    <If condition={publicView}>
                      {
                        // communityDetails?.isRepresented &&
                        isAccessible &&
                          communityDetails?.gettingAround.length > 0 &&
                          communityDetails?.gettingAround?.[0] &&
                          (communityDetails?.gettingAround?.[0]?.dedicatedBikingPaths ||
                            communityDetails?.gettingAround?.[0]?.dedicatedWalkingPaths ||
                            communityDetails?.gettingAround?.[0]?.regionalAirport ||
                            communityDetails?.gettingAround?.[0]?.localAirport ||
                            communityDetails?.gettingAround?.[0]?.publicTransType !== '' ||
                            communityDetails?.gettingAround?.[0]?.gettingAroundDesc !== '') && (
                            <li>
                              <ScrollLink
                                activeClass="active"
                                className="Section9"
                                to="Section9"
                                spy={true}
                                smooth={true}
                                duration={500}
                                offset={-100}
                              >
                                Getting Around
                              </ScrollLink>
                            </li>
                          )
                      }
                    </If>

                    <li>
                      <ScrollLink
                        activeClass="active"
                        className="Section10"
                        to="Section10"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-100}
                      >
                        Education
                      </ScrollLink>
                    </li>
                    <If condition={!publicView}>
                      {!isAccessible &&
                      ((communityDetails?.healthFacility?.length > 0 &&
                        communityDetails?.healthFacility?.find(
                          (x) => x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                        )) ||
                        (communityDetails?.healthInsights?.length > 0 &&
                          communityDetails?.healthInsights?.[0]?.healthCareDesc != '') ||
                        false) ? (
                        <li>
                          <ScrollLink
                            activeClass="active"
                            className="Section11"
                            to="Section11"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={-100}
                          >
                            Healthcare
                          </ScrollLink>
                        </li>
                      ) : (
                        isAccessible && (
                          <li>
                            <ScrollLink
                              activeClass="active"
                              className="Section11"
                              to="Section11"
                              spy={true}
                              smooth={true}
                              duration={500}
                              offset={-100}
                            >
                              Healthcare
                            </ScrollLink>
                          </li>
                        )
                      )}
                    </If>

                    <If condition={publicView}>
                      {
                        <If
                          condition={
                            isAccessible &&
                            ((communityDetails?.healthInsights?.length > 0 &&
                              communityDetails?.healthInsights?.[0]?.healthCareDesc !== '') ||
                              (communityDetails?.healthFacility?.length > 0 &&
                                communityDetails?.healthFacility?.find(
                                  (x) =>
                                    x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                                )) ||
                              false)
                          }
                        >
                          <li>
                            <ScrollLink
                              activeClass="active"
                              className="Section11"
                              to="Section11"
                              spy={true}
                              smooth={true}
                              duration={500}
                              offset={-100}
                            >
                              Healthcare
                            </ScrollLink>
                          </li>
                        </If>
                      }
                    </If>
                    <Divider />
                    <Box mb={2} mt={2}>
                      <Link
                        href={`${
                          communityDetails?.planetReCommunityId
                            ? `${homesBaseUrlForCommunity}${communityDetails.planetReCommunityId}`
                            : ''
                        }`}
                        target="_blank"
                        disable={false}
                        underline="none"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          fullWidth
                          // startIcon={<HouseIcon />}
                        >
                          View Homes
                        </Button>
                      </Link>
                    </Box>
                  </ul>
                </Box>
              </Grid>
              <Grid item xs={12} sm={8}>
                <If condition={isAccessible && !publicView}>
                  <CommunityProfileTracker communityDetails={communityDetails} />
                </If>
                <Box component="div" className="ScrollerBoxCD">
                  <If condition={isAccessible && publicView}>
                    <ViewPublic setPublicView={setPublicView} />
                  </If>
                  <Choose>
                    <When
                      // condition={communityDetails && !communityDetails?.isRepresented && !isAdmin}
                      condition={communityDetails && !communityDetails?.isRepresented && !isAdmin}
                    >
                      <Box key={communityDetails?.id}>
                        <Element name="">
                          <Box
                            className="CardBannerSection"
                            style={{
                              backgroundImage: `url(${
                                communityDetails?.images
                                  ? backendUrl + communityDetails?.images
                                  : require(`../../../assets/images/community-Empty-Image.jpg`)
                                      .default
                              })`,
                            }}
                          ></Box>
                          <CommunityCoverPhoto
                            communityDetails={communityDetails}
                            listView={false}
                            favStatus={loc?.state?.isFavourite}
                            actionButton={true}
                            isAccessible={isAccessible}
                            setPublicView={setPublicView}
                            publicView={publicView}
                            fillCommunityDetails={fillCommunityDetails}
                          />
                        </Element>
                        <If condition={!publicView}>
                          {!isAccessible &&
                          ((communityDetails?.amenities?.length &&
                            communityDetails?.amenities?.find(
                              (x) =>
                                x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                            )) ||
                            (communityDetails?.amenityDescription?.length &&
                              communityDetails?.amenityDescription?.find(
                                (x) =>
                                  x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                              )?.amenityDesc != '') ||
                            false) ? (
                            <Element name="Section1" className="AmenitiesModule">
                              <Amenities
                                communityInfo={communityDetails}
                                isAccessible={isAccessible}
                                publicView={publicView}
                                loggedInUserData={loggedInUserData}
                              />
                            </Element>
                          ) : (
                            isAccessible && (
                              <Element name="Section1" className="AmenitiesModule">
                                <Amenities
                                  communityInfo={communityDetails}
                                  isAccessible={isAccessible}
                                  publicView={publicView}
                                  loggedInUserData={loggedInUserData}
                                />
                              </Element>
                            )
                          )}
                        </If>
                        <If condition={!publicView}>
                          {(!isAccessible &&
                            communityDetails?.communityVideo &&
                            communityDetails?.communityVideo.length > 0 &&
                            communityDetails?.communityVideo
                              ?.find(
                                (x) =>
                                  x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                              )
                              ?.approvedStatus?.toLowerCase() == 'approved') ||
                          (!isAccessible &&
                            communityDetails?.aboutDesc &&
                            communityDetails?.aboutDesc !== '') ? (
                            <Element name="Section3" className="AboutCommunityModule">
                              <AboutCommunity
                                communityInfo={communityDetails}
                                isAccessible={isAccessible}
                                publicView={publicView}
                                loggedInUserData={loggedInUserData}
                              />
                            </Element>
                          ) : (
                            isAccessible && (
                              <Element name="Section3" className="AboutCommunityModule">
                                <AboutCommunity
                                  communityInfo={communityDetails}
                                  isAccessible={isAccessible}
                                  publicView={publicView}
                                  loggedInUserData={loggedInUserData}
                                />
                              </Element>
                            )
                          )}
                        </If>
                        <If condition={publicView}>
                          <If
                            condition={
                              (communityDetails?.communityVideo &&
                                communityDetails?.communityVideo.length > 0 &&
                                communityDetails?.communityVideo
                                  ?.find(
                                    (x) =>
                                      x.repId === communityDetails?.userrole[0]?.id ||
                                      x.isAdminEntry,
                                  )
                                  ?.approvedStatus?.toLowerCase() == 'approved') ||
                              (communityDetails?.aboutDesc && communityDetails?.aboutDesc !== '')
                            }
                          >
                            <Element name="Section3" className="AboutCommunityModule">
                              <AboutCommunity
                                communityInfo={communityDetails}
                                isAccessible={isAccessible}
                                publicView={publicView}
                              />
                            </Element>
                          </If>
                        </If>
                        <Element name="Section5">
                          <Jobs communityInfo={communityDetails} />
                        </Element>
                        <Element name="Section2">
                          <Residents communityInfo={communityDetails} />
                        </Element>
                        <Element name="Section7">
                          <CostOfLiving communityInfo={communityDetails} />
                        </Element>
                        <If condition={!publicView}>
                          <Choose>
                            <When
                              condition={
                                !isAccessible &&
                                communityDetails?.climate &&
                                communityDetails?.climate.length > 0 &&
                                communityDetails?.climate?.[0] &&
                                (communityDetails?.climate?.[0]?.climateDesc !== '' ||
                                  communityDetails?.climate?.[0]?.climateType !== '')
                              }
                            >
                              <Element name="Section8" className="ClimateModule">
                                <Climate
                                  communityInfo={communityDetails}
                                  communityDetails={communityDetails}
                                  isAccessible={isAccessible}
                                  publicView={publicView}
                                  loggedInUserData={loggedInUserData}
                                />
                              </Element>
                            </When>
                            <When condition={isAccessible}>
                              <Element name="Section8" className="ClimateModule">
                                <Climate
                                  communityInfo={communityDetails}
                                  communityDetails={communityDetails}
                                  isAccessible={isAccessible}
                                  publicView={publicView}
                                  loggedInUserData={loggedInUserData}
                                />
                              </Element>
                            </When>
                          </Choose>
                        </If>

                        <If condition={!publicView}>
                          <Choose>
                            <When
                              condition={
                                !isAccessible &&
                                communityDetails?.gettingAround?.length > 0 &&
                                communityDetails?.gettingAround?.[0] &&
                                (communityDetails?.gettingAround?.[0]?.dedicatedBikingPaths ||
                                  communityDetails?.gettingAround?.[0]?.dedicatedWalkingPaths ||
                                  communityDetails?.gettingAround?.[0]?.regionalAirport ||
                                  communityDetails?.gettingAround?.[0]?.localAirport ||
                                  communityDetails?.gettingAround?.[0]?.publicTransType !== '' ||
                                  communityDetails?.gettingAround?.[0]?.gettingAroundDesc !== '')
                              }
                            >
                              <Element name="Section9" className="GettingAroundModule">
                                <GettingAround
                                  communityInfo={communityDetails}
                                  communityDetails={communityDetails}
                                  isAccessible={isAccessible}
                                  publicView={publicView}
                                  loggedInUserData={loggedInUserData}
                                />
                              </Element>
                            </When>
                            <When condition={isAccessible && communityDetails?.isRepresented}>
                              <Element name="Section9" className="GettingAroundModule">
                                <GettingAround
                                  communityInfo={communityDetails}
                                  communityDetails={communityDetails}
                                  isAccessible={isAccessible}
                                  publicView={publicView}
                                  loggedInUserData={loggedInUserData}
                                />
                              </Element>
                            </When>
                          </Choose>
                        </If>
                        <Element name="Section10" className="EducationModule">
                          <Education
                            communityInfo={communityDetails}
                            communityDetails={communityDetails}
                            isAccessible={isAccessible}
                            publicView={publicView}
                            loggedInUserData={loggedInUserData}
                          />
                        </Element>
                        <If condition={!publicView}>
                          {!isAccessible &&
                          ((communityDetails?.healthFacility?.length > 0 &&
                            communityDetails?.healthFacility?.find(
                              (x) =>
                                x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                            )) ||
                            (communityDetails?.healthInsights?.length > 0 &&
                              communityDetails?.healthInsights?.[0]?.healthCareDesc != '') ||
                            false) ? (
                            <Element name="Section11" className="HealthcareModule">
                              <Healthcare
                                communityInfo={communityDetails}
                                isAccessible={isAccessible}
                                publicView={publicView}
                                loggedInUserData={loggedInUserData}
                              />
                            </Element>
                          ) : (
                            isAccessible && (
                              <Element name="Section11" className="HealthcareModule">
                                <Healthcare
                                  communityInfo={communityDetails}
                                  isAccessible={isAccessible}
                                  publicView={publicView}
                                  loggedInUserData={loggedInUserData}
                                />
                              </Element>
                            )
                          )}
                        </If>
                        <If condition={publicView}>
                          {console.log(
                            isAccessible &&
                              ((communityDetails?.healthInsights?.length > 0 &&
                                communityDetails?.healthInsights?.[0]?.healthCareDesc !== '') ||
                                (communityDetails?.healthFacility?.length > 0 &&
                                  communityDetails?.healthFacility?.find(
                                    (x) =>
                                      x.repId === communityDetails?.userrole[0]?.id ||
                                      x.isAdminEntry,
                                  )) ||
                                false),
                            8686,
                          )}
                          <If
                            condition={
                              isAccessible &&
                              ((communityDetails?.healthInsights?.length > 0 &&
                                communityDetails?.healthInsights?.[0]?.healthCareDesc !== '') ||
                                (communityDetails?.healthFacility?.length > 0 &&
                                  communityDetails?.healthFacility?.find(
                                    (x) =>
                                      x.repId === communityDetails?.userrole[0]?.id ||
                                      x.isAdminEntry,
                                  )) ||
                                false)
                            }
                          >
                            <li>
                              <ScrollLink
                                activeClass="active"
                                className="Section11"
                                to="Section11"
                                spy={true}
                                smooth={true}
                                duration={500}
                                offset={-100}
                              >
                                Healthcare
                              </ScrollLink>
                            </li>
                          </If>
                        </If>
                      </Box>
                    </When>
                    {/* <When condition={communityDetails && communityDetails?.isRepresented}> */}

                    <When condition={communityDetails}>
                      <Element name="" className="CommunityCoverPhotoModule">
                        <CommunityCoverPhoto
                          communityDetails={communityDetails}
                          listView={false}
                          favStatus={loc?.state?.isFavourite}
                          actionButton={true}
                          isAccessible={isAccessible}
                          setPublicView={setPublicView}
                          publicView={publicView}
                          fillCommunityDetails={fillCommunityDetails}
                        />
                      </Element>

                      <If condition={!publicView}>
                        {!isAccessible &&
                        communityDetails?.amenities?.length &&
                        (communityDetails?.amenities?.find(
                          (x) => x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                        ) ||
                          communityDetails?.amenityDescription?.find(
                            (x) => x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                          )?.amenityDesc != '' ||
                          false) ? (
                          <Element name="Section1" className="AmenitiesModule">
                            <Amenities
                              communityInfo={communityDetails}
                              isAccessible={isAccessible}
                              publicView={publicView}
                              loggedInUserData={loggedInUserData}
                            />
                          </Element>
                        ) : (
                          isAccessible && (
                            <Element name="Section1" className="AmenitiesModule">
                              <Amenities
                                communityInfo={communityDetails}
                                isAccessible={isAccessible}
                                publicView={publicView}
                                loggedInUserData={loggedInUserData}
                              />
                            </Element>
                          )
                        )}
                      </If>
                      <If condition={publicView}>
                        <If
                          condition={
                            isAccessible &&
                            ((communityDetails?.amenities?.length &&
                              communityDetails?.amenities?.find(
                                (x) =>
                                  x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                              )) ||
                              (communityDetails?.amenityDescription?.length &&
                                communityDetails?.amenityDescription?.find(
                                  (x) =>
                                    x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                                )?.amenityDesc != '') ||
                              false)
                          }
                        >
                          <Element name="Section1" className="AmenitiesModule">
                            <Amenities
                              communityInfo={communityDetails}
                              isAccessible={isAccessible}
                              publicView={publicView}
                              loggedInUserData={loggedInUserData}
                            />
                          </Element>
                        </If>
                        {/* {((communityDetails?.amenities && communityDetails?.amenities.length > 0) ||
                          communityDetails?.amenityDescription[0]?.amenityDesc !== '') && (

                        )} */}
                      </If>
                      <If condition={communityDetails?.isRepresented}>
                        <Element name="Section2" className="InsightsByModule">
                          <InsightsBy
                            communityInfo={communityDetails}
                            isAccessible={isAccessible}
                            publicView={publicView}
                            loggedInUserData={loggedInUserData}
                          />
                        </Element>
                      </If>

                      <If condition={!publicView}>
                        {(!isAccessible &&
                          communityDetails?.communityVideo &&
                          communityDetails?.communityVideo.length > 0 &&
                          communityDetails?.communityVideo
                            ?.find(
                              (x) =>
                                x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                            )
                            ?.approvedStatus?.toLowerCase() == 'approved') ||
                        (!isAccessible &&
                          communityDetails?.aboutDesc &&
                          communityDetails?.aboutDesc !== '') ? (
                          <Element name="Section3" className="AboutCommunityModule">
                            <AboutCommunity
                              communityInfo={communityDetails}
                              isAccessible={isAccessible}
                              publicView={publicView}
                            />
                          </Element>
                        ) : (
                          isAccessible && (
                            <Element name="Section3" className="AboutCommunityModule">
                              <AboutCommunity
                                communityInfo={communityDetails}
                                isAccessible={isAccessible}
                                publicView={publicView}
                              />
                            </Element>
                          )
                        )}
                      </If>

                      <If condition={publicView}>
                        <If
                          condition={
                            (communityDetails?.communityVideo &&
                              communityDetails?.communityVideo.length > 0 &&
                              communityDetails?.communityVideo
                                ?.find(
                                  (x) =>
                                    x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                                )
                                ?.approvedStatus?.toLowerCase() == 'approved') ||
                            (communityDetails?.aboutDesc && communityDetails?.aboutDesc !== '')
                          }
                        >
                          <Element name="Section3" className="AboutCommunityModule">
                            <AboutCommunity
                              communityInfo={communityDetails}
                              isAccessible={isAccessible}
                              publicView={publicView}
                            />
                          </Element>
                        </If>
                      </If>

                      {/* <Element name="Section4" className="CommunityMapModule">
                        <CommunityMap />
                      </Element> */}

                      <Element name="Section5" className="JobsModule">
                        <Jobs
                          communityInfo={communityDetails}
                          communityDetails={communityDetails}
                          isAccessible={isAccessible}
                          publicView={publicView}
                          loggedInUserData={loggedInUserData}
                        />
                      </Element>
                      <Element name="Section6" className="ResidentsModule">
                        <Residents
                          communityInfo={communityDetails}
                          communityDetails={communityDetails}
                          isAccessible={isAccessible}
                          publicView={publicView}
                          loggedInUserData={loggedInUserData}
                        />
                      </Element>
                      <Element name="Section7" className="CostOfLivingModule">
                        <CostOfLiving
                          communityInfo={communityDetails}
                          communityDetails={communityDetails}
                          isAccessible={isAccessible}
                          publicView={publicView}
                          loggedInUserData={loggedInUserData}
                        />
                      </Element>
                      <If condition={!publicView}>
                        <Choose>
                          <When
                            condition={
                              !isAccessible &&
                              communityDetails?.climate &&
                              communityDetails?.climate.length > 0 &&
                              communityDetails?.climate?.[0] &&
                              (communityDetails?.climate?.[0]?.climateDesc !== '' ||
                                communityDetails?.climate?.[0]?.climateType !== '')
                            }
                          >
                            <Element name="Section8" className="ClimateModule">
                              <Climate
                                communityInfo={communityDetails}
                                communityDetails={communityDetails}
                                isAccessible={isAccessible}
                                publicView={publicView}
                                loggedInUserData={loggedInUserData}
                              />
                            </Element>
                          </When>
                          <When condition={isAccessible}>
                            <Element name="Section8" className="ClimateModule">
                              <Climate
                                communityInfo={communityDetails}
                                communityDetails={communityDetails}
                                isAccessible={isAccessible}
                                publicView={publicView}
                                loggedInUserData={loggedInUserData}
                              />
                            </Element>
                          </When>
                        </Choose>
                      </If>

                      <If condition={publicView}>
                        <If
                          condition={
                            communityDetails?.climate &&
                            communityDetails?.climate?.length > 0 &&
                            communityDetails?.climate?.[0] &&
                            (communityDetails?.climate?.[0]?.climateDesc !== '' ||
                              communityDetails?.climate?.[0]?.climateType !== '')
                          }
                        >
                          <Element name="Section8" className="ClimateModule">
                            <Climate
                              communityInfo={communityDetails}
                              communityDetails={communityDetails}
                              isAccessible={isAccessible}
                              publicView={publicView}
                              loggedInUserData={loggedInUserData}
                            />
                          </Element>
                        </If>
                      </If>
                      <If condition={!publicView}>
                        <Choose>
                          <When
                            condition={
                              !isAccessible &&
                              communityDetails?.gettingAround?.length > 0 &&
                              communityDetails?.gettingAround?.[0] &&
                              (communityDetails?.gettingAround?.[0]?.dedicatedBikingPaths ||
                                communityDetails?.gettingAround?.[0]?.dedicatedWalkingPaths ||
                                communityDetails?.gettingAround?.[0]?.regionalAirport ||
                                communityDetails?.gettingAround?.[0]?.localAirport ||
                                communityDetails?.gettingAround?.[0]?.publicTransType !== '' ||
                                communityDetails?.gettingAround?.[0]?.gettingAroundDesc !== '')
                            }
                          >
                            <Element name="Section9" className="GettingAroundModule">
                              <GettingAround
                                communityInfo={communityDetails}
                                communityDetails={communityDetails}
                                isAccessible={isAccessible}
                                publicView={publicView}
                                loggedInUserData={loggedInUserData}
                              />
                            </Element>
                          </When>
                          <When condition={isAccessible}>
                            <Element name="Section9" className="GettingAroundModule">
                              <GettingAround
                                communityInfo={communityDetails}
                                communityDetails={communityDetails}
                                isAccessible={isAccessible}
                                publicView={publicView}
                                loggedInUserData={loggedInUserData}
                              />
                            </Element>
                          </When>
                        </Choose>
                      </If>

                      <If condition={publicView}>
                        {
                          // communityDetails?.isRepresented &&
                          isAccessible &&
                            communityDetails?.gettingAround.length > 0 &&
                            communityDetails?.gettingAround?.[0] &&
                            (communityDetails?.gettingAround?.[0]?.dedicatedBikingPaths ||
                              communityDetails?.gettingAround?.[0]?.dedicatedWalkingPaths ||
                              communityDetails?.gettingAround?.[0]?.regionalAirport ||
                              communityDetails?.gettingAround?.[0]?.localAirport ||
                              communityDetails?.gettingAround?.[0]?.publicTransType !== '' ||
                              communityDetails?.gettingAround?.[0]?.gettingAroundDesc !== '') && (
                              <Element name="Section9" className="GettingAroundModule">
                                <GettingAround
                                  communityInfo={communityDetails}
                                  communityDetails={communityDetails}
                                  isAccessible={isAccessible}
                                  publicView={publicView}
                                  loggedInUserData={loggedInUserData}
                                />
                              </Element>
                            )
                        }
                      </If>
                      <Element name="Section10" className="EducationModule">
                        <Education
                          communityInfo={communityDetails}
                          communityDetails={communityDetails}
                          isAccessible={isAccessible}
                          publicView={publicView}
                          loggedInUserData={loggedInUserData}
                        />
                      </Element>

                      <If condition={!publicView}>
                        {!isAccessible &&
                        ((communityDetails?.healthFacility?.length > 0 &&
                          communityDetails?.healthFacility?.find(
                            (x) => x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                          )) ||
                          (communityDetails?.healthInsights?.length > 0 &&
                            communityDetails?.healthInsights?.[0]?.healthCareDesc != '') ||
                          false) ? (
                          <Element name="Section11" className="HealthcareModule">
                            <Healthcare
                              communityInfo={communityDetails}
                              isAccessible={isAccessible}
                              publicView={publicView}
                              loggedInUserData={loggedInUserData}
                            />
                          </Element>
                        ) : (
                          isAccessible && (
                            <Element name="Section11" className="HealthcareModule">
                              <Healthcare
                                communityInfo={communityDetails}
                                isAccessible={isAccessible}
                                publicView={publicView}
                                loggedInUserData={loggedInUserData}
                              />
                            </Element>
                          )
                        )}
                      </If>

                      <If condition={publicView}>
                        {console.log(
                          communityDetails?.healthFacility?.find(
                            (x) => x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                          ),
                          5656,
                        )}
                        <If
                          condition={
                            isAccessible &&
                            ((communityDetails?.healthFacility?.length > 0 &&
                              communityDetails?.healthFacility?.find(
                                (x) =>
                                  x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                              )) ||
                              (communityDetails?.healthInsights?.length > 0 &&
                                communityDetails?.healthInsights?.[0]?.healthCareDesc !== '') ||
                              false)
                          }
                        >
                          <Element name="Section11" className="HealthcareModule">
                            <Healthcare
                              communityInfo={communityDetails}
                              isAccessible={isAccessible}
                              publicView={publicView}
                              loggedInUserData={loggedInUserData}
                            />
                          </Element>
                        </If>
                      </If>
                    </When>
                  </Choose>
                </Box>
                {/* <Paper className="WhitePaper HomesBoxCD" component="div">
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography color="primary" variant="h2" className="BoxHeading">
                        Homes In {communityDetails.communityNameText}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="CommonDetailBoxGrid">
                      <Box className="communityDetailsCarousel">
                        <HouseList showSearch={false} communityId={communityDetails?.id} />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} className="ViewButton">
                    {communityDetails?.planetReCommunityId ? (
                      <Link
                        href={`${
                          communityDetails?.planetReCommunityId
                            ? `${homesBaseUrlForCommunity}${communityDetails.planetReCommunityId}`
                            : ''
                        }`}
                        target="_blank"
                        disable={false}
                        underline="none"
                        color="primary"
                        className="RightFloatBtn"
                      >
                        <ViewButton />
                      </Link>
                    ) : (
                      <Link className="RightFloatBtn">
                        <Tooltip title="The link is available in the production environment.">
                          <ViewButton />
                        </Tooltip>
                      </Link>
                    )}
                  </Grid>
                </Paper> */}
                <Box mb={2} mt={2} pl={2} pr={2} className="ViewHomesButton">
                  <Link
                    href={`${
                      communityDetails?.planetReCommunityId
                        ? `${homesBaseUrlForCommunity}${communityDetails.planetReCommunityId}`
                        : ''
                    }`}
                    target="_blank"
                    disable={false}
                    underline="none"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      fullWidth
                      // startIcon={<HouseIcon />}
                    >
                      View Homes
                    </Button>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      ) : (
        <>
          <Container>
            <Grid container className="PageSpacing">
              <Grid item xs={12}>
                {/* No content to display */}
                {/* <Loader /> */}
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );
};

CommunityDetails.defaultProps = {};
export default CommunityDetails;
